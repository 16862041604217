import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { includes } from 'lodash';
import { selectRoutes } from 'src/apiRoutes';
import { setLanding } from 'src/actions/landing';
import { updatePhone } from 'src/containers/AppBase/actions';
import { LOAD_PAGE, GEEK_SQUAD_PARTNER_MULTIPLE_ACCOUNT_SKU_LAYOUT } from 'src/constants/common';
import { LANDING_TEMPLATES } from 'src/constants/landings';
import { LANDING_PHONE_COOKIE } from 'src/constants/tracking';
import { getUpsellPlanIds } from 'src/components/Plans/Pricing/utils';
import { pageLoadingError, pageLoaded, layoutUpdate } from 'src/actions/common';
import { resolveLayout } from 'src/utils/layout';
import { requestStarted, requestFinished } from 'src/utils/request';
import { splitioSelectors, splitioConstants } from 'src/components/SplitIO';
import { PAGE_NAME, GEEK_SQUAD_VS_HELLOTECH_SEO_NAME, INSTALL_SETUP_SEO_NAME } from './constants';
import { updateCart } from '../AddSkuPage/actions';

const getLayout = (landing = {}, { installSetupTreatment = splitioConstants.OFF }) => {
  const { seoName, template } = landing;
  /** Use Geek Squad layout per https://hellotech.atlassian.net/browse/GROW-403 mentioning similarity in Top Navs */
  const shouldUseGeekSquadLayout =
    seoName === GEEK_SQUAD_VS_HELLOTECH_SEO_NAME ||
    (seoName === INSTALL_SETUP_SEO_NAME && installSetupTreatment === splitioConstants.ON);
  if (shouldUseGeekSquadLayout) return GEEK_SQUAD_PARTNER_MULTIPLE_ACCOUNT_SKU_LAYOUT;
  return resolveLayout(template);
};

function shouldNotLoadReviews(landing) {
  const pagesWithReviews = [
    LANDING_TEMPLATES.FEATURED_CONTENT_OR_SKU,
    LANDING_TEMPLATES.OPTIMUM_GENERAL,
    LANDING_TEMPLATES.SUDDENLINK_GENERAL,
    LANDING_TEMPLATES.OPTIMUM_MOVERS,
    LANDING_TEMPLATES.SUDDENLINK_MOVERS,
    LANDING_TEMPLATES.OPTIMUM_SUPPORT,
    LANDING_TEMPLATES.SUDDENLINK_SUPPORT,
    LANDING_TEMPLATES.MULTIPLE_ACCOUNT_SKU,
  ];
  return !includes(pagesWithReviews, landing.template);
}

function* loadReviews(landing) {
  if (shouldNotLoadReviews(landing)) return [];

  const routes = yield call(selectRoutes);
  let reviews = yield select((state) =>
    state.getIn(['entities', 'reviews', 'landings', landing.id.toString()]),
  );
  if (reviews) {
    reviews = reviews.toJS();
  } else {
    const requestResult = yield call(routes.reviews.list);
    if (!requestResult.err) {
      reviews = requestResult.data.reviews;
    } else {
      yield put(pageLoadingError('home', requestResult));
      return null;
    }
  }

  return reviews;
}

function* checkForPromoCode(landing) {
  if (landing.coupon) {
    const routes = yield call(selectRoutes);
    yield put(requestStarted());
    const response = yield call(routes.cart.addCoupon, { code: landing.coupon.text, force: true });
    yield put(requestFinished());

    if (!response.err) {
      const { cart } = response.data;
      yield put(updateCart({ cart }));
    }
  }
}

// Some landing pages may show the plan upsell module
function* checkForSubscriptionPrices(landing, currentLanding, isPlanPricingTest = false) {
  const routes = yield call(selectRoutes);
  const { monthlyID } = yield call(getUpsellPlanIds, { isPlanPricingTest });
  const results = yield all(
    landing.skus.reduce(
      (acct, sku) => ({
        ...acct,
        [sku.id]: call(routes.skus.estimate, { skuId: sku.id }, {enforce_plan_id: monthlyID}),
      }),
      {},
    ),
  );

  const skus = Object.keys(results).reduce((acct, key) => {
    const result = results[key];
    if (result.err) return acct;

    const {
      data: { prices },
    } = result;
    const sku = landing.skus.find((s) => s.id === +key);
    if (!sku) return acct;

    return [...acct, { ...sku, subscriptionPrices: prices }];
  }, []);

  if (skus.length) {
    yield put(setLanding({ ...landing, skus, currentLanding }));
  }
}

function* pageSaga({ name, isPlanPricingTest = false }) {
  const routes = yield call(selectRoutes);

  let landing = yield select((state) => state.getIn(['entities', 'landings', name]));
  const currentLanding = yield select((state) => state.getIn(['entities', 'currentLanding']));

  if (!landing) {
    const response = yield call(routes.landings.find, { name });
    if (response.err) {
      yield put(pageLoadingError(PAGE_NAME, response));
      return;
    }

    landing = response.data.landing;
  } else {
    landing = landing.toJS();
  }

  const { phone } = landing;
  if (phone) {
    yield put(updatePhone({ phone }));
    Cookies.set(LANDING_PHONE_COOKIE.NAME, phone, { expires: LANDING_PHONE_COOKIE.EXPIRATION });
  }
  const reviews = yield loadReviews(landing);
  const installSetupTreatment = yield select(
    splitioSelectors.treatmentSelector(splitioConstants.SPLITNAME_LP_INSTALL_SETUP_SHOW_SITE_NAV),
  );
  const partnerLayout = getLayout(landing, { installSetupTreatment });
  yield checkForPromoCode(landing);
  yield put(layoutUpdate(partnerLayout));
  yield put(pageLoaded(PAGE_NAME, { landing, reviews }));
  yield checkForSubscriptionPrices(landing, currentLanding, isPlanPricingTest);
}

function* pageFlow() {
  yield takeLatest((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
}

export default [pageFlow];
