import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import Icon from 'src/components/HTKit/Icon';
import Grid from 'src/components/HTKit/Grid/Grid';

// Utils
import HTKustomerChat from 'src/utils/Kustomer/HTKustomerChat';
import { searchResultsClickedSegment } from 'src/components/Searchbar/actions';
import { quickLinks } from '../constants';
import { getDevice } from '../helpers';

// Styles
import styles from '../styles.scss';

const QuickLinks = ({ iconClick, searchTerm }) => {
  const dispatch = useDispatch();
  const device = getDevice();

  return (
    <>
      <h4 className="marginTop-medium paddingBottom-small paddingX-small1" data-testid="search-quick-link-title">Quick Links</h4>
      <div onClick={iconClick}>
        <Grid.FullWidth classes={styles.categoryContainer}>
          {quickLinks.map((item) => {
            const onClick = () => {
              dispatch(
                searchResultsClickedSegment({
                  searchTerm,
                  clickedItem: item.name,
                  attributes: null,
                  device,
                }),
              );
            };
            return (
              <Grid.Row key={item.id} classes={styles.customRow}>
                <Link
                  to={item.link}
                  onClick={onClick}
                  className={cn('paddingX-small1', styles.clickContainer)}
                >
                  <Grid.Column classes={styles.columnContainer}>
                    <div className={styles.innerContainer}>
                      <h6 className={styles.name}>{item.name}</h6>
                    </div>
                    <Icon name="chevron-right" className={styles.chevron} />
                  </Grid.Column>
                </Link>
              </Grid.Row>
            );
          })}
          <Grid.Row classes={styles.customRow}>
            <Grid.Column>
              <p className="p2 paddingLeft-small paddingTop-medium">
                Can’t find what you’re looking for?{' '}
                <Link
                  className={styles.chatLink}
                  onClick={() => {
                    dispatch(
                      searchResultsClickedSegment({
                        searchTerm,
                        clickedItem: 'chat',
                        attributes: null,
                        device,
                      }),
                    );
                    HTKustomerChat.open();
                  }}
                >
                  Chat with Us
                </Link>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid.FullWidth>
      </div>
    </>
  );
};

QuickLinks.defaultProps = {
  iconClick: () => {},
};

QuickLinks.propTypes = {
  iconClick: PropTypes.func,
  searchTerm: PropTypes.string,
};


export default QuickLinks;
