import Cookies from 'js-cookie';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import { NOTICE_TYPE } from 'src/constants/common';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { invalidForm, clearPageNotices } from 'src/actions/common';
import { updateAuth } from 'src/containers/AppBase/actions';
import { loadUserSaga } from 'src/containers/AppBase/sagas';
import { LOGIN, VALIDATE_AUTH_CODE } from './constants';
import { loggedIn } from './actions';
import { setLoginPasscodeError, clearLoginPasscodeError, clearUserInfo } from '../Modal/actions';
import { htToast } from 'src/components/HTKit/Toast';

const GENERIC_API_ERROR_MESSAGE = 'Invalid email or password. Please try again.';

/**
 * Handles the traditional email/password login flow.
 * Authenticates the user, updates the auth token, and handles login-related actions.
 */
export function* handleLogin(action) {
  const { email, password, page = 'login', onFinish, skipRedirect } = action;
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  yield put(clearPageNotices(page));
  const response = yield call(routes.users.login, { type: 'Client', email, password });
  yield put(requestFinished());

  if (onFinish) onFinish(response);

  if (response.err) {
    const { status } = response.err.response;
    if (status === 401) {
      // not authenticated
      yield put(displayErrors(response));
    } else {
      const notice = {
        type: NOTICE_TYPE.ERROR,
        name: 'general',
        message: GENERIC_API_ERROR_MESSAGE,
      };
      yield put(invalidForm(page, { password: notice.message }));
    }
  } else {
    const authToken = response.data.auth_token;
    yield put(updateAuth({ authToken }));
    yield call(loadUserSaga);
    if (Cookies.get('new_user')) {
      Cookies.remove('new_user');
    }

    if (!skipRedirect) {
      // Handle any redirects and subsequent actions from logging in.
      yield put(loggedIn());
    }
  }
}

/** Handles the welcome toast. */
function showWelcomeToast(user) {
  const welcomeBackMessage = user.firstName ? `Welcome back, ${user.firstName}!` : 'Welcome back!';
  htToast(welcomeBackMessage);
}

/**
 * Handles the passwordless login flow by validating the auth code.
 * Verifies the code, updates the auth token, and handles login-related actions.
 */
export function* handleValidateAuthCode(action) {
  const { email, code } = action.payload;

  // The Passcode modal can be opened in normal login flow or during checkout.
  // If it's in the checkout flow, we need to pass the isPasswordlessCheckout flag
  // for downstream effects.
  const isPasswordlessCheckout = yield select((state) =>
    state.getIn(['components', 'loginModal', 'isPasswordlessCheckout']),
  );

  const routes = yield call(selectRoutes);

  yield put(clearLoginPasscodeError());
  yield put(requestStarted());
  const response = yield call(routes.users.validateAuthCode, { email, code });
  yield put(requestFinished());

  if (response.err) {
    const errorMessage = response.data?.errors[0] || 'Something went wrong';
    yield put(setLoginPasscodeError({ error: errorMessage }));
    return;
  }
  const { user } = response.data;

  yield put(updateAuth({ authToken: user.authToken }));
  yield call(loadUserSaga);

  if (Cookies.get('new_user')) {
    Cookies.remove('new_user');
  }

  showWelcomeToast(user);
  yield put(clearUserInfo()); // Cleanup modal state used for auth

  yield put(loggedIn({ isPasswordlessCheckout }));
}

export function* loginFlow() {
  yield takeEvery(LOGIN, handleLogin);
  yield takeEvery(VALIDATE_AUTH_CODE, handleValidateAuthCode);
}

export default [loginFlow];
