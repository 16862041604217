// Libraries
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import compact from 'lodash/compact';
// Components
import { Grid } from 'src/components/HTKit/Grid';
import NavHeader from 'src/components/Header/TopNavHeader/TopNavItemsV2/NavHeader';
import PrimaryMenu from 'src/components/Header/TopNavHeader/TopNavItemsV2/PrimaryMenu';
import SecondaryMenu from 'src/components/Header/TopNavHeader/TopNavItemsV2/SecondaryMenu';
// Hooks && Data
import useOutsideClick from 'src/hooks/useOutsideClick';
import { SKU_CATEGORY_IDS, SUB_CATEGORIES } from './constants';
// Styles
import styles from './styles.scss';

const TopNavItems = ({ skuCategoriesAndSkus, toggleTopnav, skuLinkPrefix }) => {
  const {
    POPULAR_SERVICES,
    COMPUTERS_PRINTERS,
    TV_MOUNTING,
    WIFI_NETWORK,
    SMART_HOME,
    AUDIO_VIDEO,
    MOBILE_DEVICES,
    HOME_SECURITY,
    AROUND_THE_HOME,
  } = SKU_CATEGORY_IDS;

  const columns = [
    [POPULAR_SERVICES],
    [SMART_HOME, AUDIO_VIDEO, MOBILE_DEVICES],
    [WIFI_NETWORK, HOME_SECURITY, COMPUTERS_PRINTERS],
    [TV_MOUNTING, AROUND_THE_HOME],
  ];
  const [showSecondaryNav, setShowSecondaryNav] = useState(false);
  const [toggleSlide, setToggleSlide] = useState(false);
  const [currentSelection, setCurrentSelection] = useState([
    { label: '', skus: [], subCategorySkus: [] },
  ]);
  const [selectedSubCategory, setSelectedSubCategory] = useState({});

  const wrapperRef = useRef(null);
  const groupedColumns = columns.reduce((columnGroup, column) => {
    columnGroup.push(
      compact(
        column.map((c) => {
          return skuCategoriesAndSkus.find((sku) => sku.id === c.id);
        }),
      ),
    );
    return columnGroup;
  }, []);

  const handleSubCategoryClick = (event, subCategory, category) => {
    event.stopPropagation();
    if(subCategory.showSecondaryNav) {
      setCurrentSelection([
        { label: category.name },
        { label: subCategory.name, skus: category.skus, subCategorySkus: subCategory.skus },
      ]);
      setToggleSlide(false);
      setShowSecondaryNav(true);
      setSelectedSubCategory(subCategory);
    } else {
      toggleTopnav();
    }
  };

  const addPopularToGroupedCol = () => {
    groupedColumns[0] = [SUB_CATEGORIES[SUB_CATEGORIES.length - 1]];
    return groupedColumns;
  };

  const handleBackClick = (event) => {
    event.stopPropagation();
    setToggleSlide(true);
    setShowSecondaryNav(false);
  };

  const contentStyles = cn(styles.slideContentWrapper, {
    [styles.slideIn]: showSecondaryNav,
    [styles.slideOut]: toggleSlide && !showSecondaryNav,
  });

  useOutsideClick(wrapperRef, toggleTopnav);

  const onLocationChangeCloseNav = () => {
    toggleTopnav();
  };

  return (
    <section ref={wrapperRef} className={styles.navContainer}>
      <div className={contentStyles}>
        <div className={styles.overflowContainer} data-testid="navigation-menu-sku-categories-container">
          <NavHeader
            currentItem={currentSelection}
            showSecondaryNav={showSecondaryNav}
            onBackClick={handleBackClick}
          />
          <Grid.FullWidth classes="marginAuto">
            <Grid.Row classes={cn('paddingTop-medium', styles.menuRow)}>
              {showSecondaryNav ? (
                <SecondaryMenu
                  skuLinkPrefix={skuLinkPrefix}
                  category={currentSelection[1]}
                  onSecondaryMenuItemClick={onLocationChangeCloseNav}
                  selectedSubCategory={selectedSubCategory}
                />
              ) : (
                <PrimaryMenu
                  skuLinkPrefix={skuLinkPrefix}
                  groupedColumns={addPopularToGroupedCol()}
                  handleSubCategoryClick={handleSubCategoryClick}
                  onPrimaryMenuItemClick={onLocationChangeCloseNav}
                />
              )}
            </Grid.Row>
          </Grid.FullWidth>
        </div>
      </div>
    </section>
  );
};

TopNavItems.propTypes = {
  skuCategoriesAndSkus: PropTypes.array,
  toggleTopnav: PropTypes.func,
  skuLinkPrefix: PropTypes.string,
  topNavIsOpen: PropTypes.bool,
};
export default React.memo(TopNavItems);
