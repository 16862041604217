import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'HTKit/Icon';
import styles from './styles.scss';

/**
 * HTLogo
 *
 * Only to be used in headers.
 */
const HTLogo = ({ className = '', defaultCursor = false }) => {
  const iconStyles = cn(
    styles.htLogo,
    {
      [styles.defaultCursor]: defaultCursor,
      'topNavHelloTechLogo': true,
    },
    className,
  );

  return <Icon name="logo" className={iconStyles} />;
};

HTLogo.propTypes = {
  className: PropTypes.string,
  defaultCursor: PropTypes.bool,
};

export default HTLogo;
