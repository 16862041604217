import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import { resolveLayout } from 'src/utils/layout';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { push, replace, addSkuPath, productPath } from 'src/utils/paths';
import { getZipDataCookie } from 'src/utils/cookies';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { userPlanHolderSelector } from 'src/selectors/user';
import { userEntersLocationFromAClick } from 'src/selectors/router';
import { LOAD_PAGE } from 'src/constants/common';
import { layoutUpdate, pageLoadingError, pageLoaded } from 'src/actions/common';
import { SKU_TYPE } from 'src/constants/sku';
import {
  getSubscriptionPlansInfo,
  formatPlansForReducer,
  getUpsellPlanIds,
} from 'src/components/Plans/Pricing/utils';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import {
  PAGE_NAME,
  FETCH_PLAN_DETAILS,
  ADD_PLAN_TO_CART,
  FETCH_PLANS_FOR_MONTHLY_UPSELL,
} from './constants';
import {
  skuLoaded,
  loadPlanDetails,
  togglePlanModal,
  planAddedOnSkuTrack,
  plansInfoLoaded,
} from './actions';

function* pageSaga({ seoName, isPlanPricingTest = false }) {
  const routes = yield call(selectRoutes);
  const { zipCode } = getZipDataCookie() || {};
  const hasZipCodeFromTest = Boolean(zipCode);

  let sku = yield select((state) =>
    state.getIn(['entities', 'skus']).find((s) => s.get('seoName') === seoName),
  );

  if (sku && !hasZipCodeFromTest) {
    sku = sku.toJS();
  } else {
    const requestResult = yield call(routes.skus.find, { id: seoName });
    if (!requestResult.err) {
      sku = requestResult.data.sku;
    } else {
      yield put(pageLoadingError(PAGE_NAME, requestResult));
      return;
    }
  }

  // Direct to ProductPage if needed
  if (sku.type === SKU_TYPE.PRODUCT) {
    yield put(pageLoaded(PAGE_NAME, { sku }));
    yield put(replace(productPath(sku.seoName)));
    return;
  }

  let smartHomePlans;
  if (sku.includedInUpsellPlan) {
    const userProfile = yield loadUserProfileSaga();
    const subscription = yield select((state) => state.getIn(['user', 'account', 'subscription']));
    const cart = yield select((state) => state.getIn(['entities', 'cart']));

    // estimate savings if a subscription is applied
    const { monthlyID } = getUpsellPlanIds({ isPlanPricingTest });
    let estimateResult = null;
    let subscriptionPlanId = null;
    let cartPlanId = null;

    if (cart && cart.getIn(['plan', 'id'])) {
      cartPlanId = cart.getIn(['plan', 'id']);
    }
    /* We have a planId from the user */
    if (userProfile && subscription && subscription.get('id')) {
      subscriptionPlanId = subscription.get('planId');
    }
    estimateResult = yield call(routes.skus.estimate, {
      skuId: sku.id,
    }, ( !cartPlanId && !subscriptionPlanId && monthlyID ? { enforce_plan_id:  monthlyID} : {}),
    );

    if (estimateResult && !estimateResult.err) {
      const { prices } = estimateResult.data;
      sku.subscriptionPrices = prices;
    }

    // fetch upsell subscription plans info - for monthly/yearly subscriptions
    const userHasSubscription = yield select((state) =>
      state.getIn(['user', 'account', 'hasSubscription']),
    );
    if (!userHasSubscription) {
      const plansResult = yield call(getSubscriptionPlansInfo, { api: routes });
      if (!plansResult.err) {
        const {
          data: { plans: planProducts },
        } = plansResult;
        smartHomePlans = formatPlansForReducer({
          planProducts,
          featured: false,
          isPlanPricingTest,
        });
      }
    }
  }

  let reviews = yield select((state) => state.getIn(['entities', 'reviews', sku.id.toString()]));
  if (reviews) {
    reviews = reviews.toJS();
  } else {
    const requestResult = yield call(routes.reviews.sku, { id: sku.id });
    if (!requestResult.err) {
      reviews = requestResult.data.reviews;
    } else {
      yield put(pageLoadingError(PAGE_NAME, requestResult));
      return;
    }
  }

  const partnerLayout = resolveLayout(sku.landingTemplateName);
  yield put(layoutUpdate(partnerLayout));
  yield put(pageLoaded(PAGE_NAME, { sku, reviews, plansInfo: smartHomePlans }));

  const fromAClick = yield select(userEntersLocationFromAClick);

  yield put(skuLoaded(sku, fromAClick));
}

function* fetchPlanDetails({ planId }) {
  yield put(requestStarted());
  const routes = yield call(selectRoutes);
  const response = yield call(routes.plans.subscriptions.show, { id: planId });
  const {
    data: { plan },
    err,
  } = response;

  if (!err) {
    yield put(loadPlanDetails({ plan }));
    yield put(requestFinished());
    yield put(togglePlanModal({ modalState: true }));
  } else {
    yield put(displayErrors(response));
    yield put(requestFinished());
  }
}

/**
  Hotfix - Only for Location and ProductSingleSku pages
*/
function* fetchPlansForMonthlyUpsell({ isPlanPricingTest = false }) {
  yield put(requestStarted());
  const routes = yield call(selectRoutes);
  const plansResult = yield call(getSubscriptionPlansInfo, { api: routes });
  if (!plansResult.err) {
    const {
      data: { plans: planProducts },
    } = plansResult;
    const smartHomePlans = formatPlansForReducer({
      planProducts,
      featured: false,
      isPlanPricingTest,
    });
    const monthlyPlan = smartHomePlans.monthly;
    yield put(loadPlanDetails({ plan: monthlyPlan }));
    yield put(plansInfoLoaded({ plansInfo: smartHomePlans }));
    yield put(requestFinished());
    yield put(togglePlanModal({ modalState: true }));
  } else {
    yield put(displayErrors(plansResult));
    yield put(requestFinished());
  }
}
/**
  End Hotfix
*/

function* addPlanToCart({ planId, skuId }) {
  const isPlanHolder = yield select((state) => userPlanHolderSelector(state));

  if (isPlanHolder) {
    yield put(togglePlanModal({ modalState: false }));
    return;
  }

  yield put(requestStarted());
  const routes = yield call(selectRoutes);
  const response = yield call(routes.cart.selectPlan, { plan_id: planId });
  const {
    data: { cart },
    err,
  } = response;

  if (!err) {
    const sku = yield select((state) => state.getIn(['entities', 'skus', `${skuId}`], null));
    yield put(updateCart({ cart }));
    yield put(requestFinished());
    yield put(push(addSkuPath(skuId)));
    yield put(planAddedOnSkuTrack(sku));
  } else {
    yield put(displayErrors(response));
    yield put(requestFinished());
  }
  yield put(togglePlanModal({ modalState: false }));
}

export function* pageFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
  yield takeLatest(FETCH_PLAN_DETAILS, fetchPlanDetails);
  yield takeLatest(ADD_PLAN_TO_CART, addPlanToCart);
  yield takeLatest(FETCH_PLANS_FOR_MONTHLY_UPSELL, fetchPlansForMonthlyUpsell);
}

export default [pageFlow];
