import { put, takeLatest, select, call } from 'redux-saga/effects';
import { last } from 'lodash';
import { push } from 'src/utils/paths';
import { updatePhone } from 'src/containers/AppBase/actions';
import { setSkuLinkPrefix } from 'src/components/Sidebar/actions';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { getUpsellPlanIds } from 'src/components/Plans/Pricing/utils';
import { selectRoutes } from 'src/apiRoutes';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { LOAD_PAGE, DEFAULT_HOME_PAGE_LAYOUT, DEFAULT_LAYOUT } from 'src/constants/common';
import { pageLoaded, pageLoadingError, layoutUpdate } from 'src/actions/common';
import { SELECT_LOCATION_PAGE, PAGE_NAME } from './constants';
import { locationUpdated } from './actions';

function* updateNavbarMenu(location) {
  const l = location.toJS != null ? location.toJS() : location;
  const path = last(l.geoPath);
  if (path && path.link) {
    const prefix = path.link;
    yield put(setSkuLinkPrefix(prefix));
  }
}

function* pageSaga({ locationPath, isPlanPricingTest = false }) {
  const routes = yield call(selectRoutes);

  let location = yield select((state) => state.getIn(['entities', 'locations', locationPath]));
  let phone;
  let layout = DEFAULT_HOME_PAGE_LAYOUT;
  if (location) {
    phone = location.get('phone');
  } else {
    const response = yield call(routes.locations.find, { locationPath });

    if (response.err) {
      yield put(pageLoadingError('location', response));
      return;
    }

    const { redirect } = response.data.location;
    if (redirect) {
      if (!process.browser) return;
      yield put(push(redirect));
      return;
    }
    location = response.data.location;
    phone = location.phone;
  }

  yield call(updateNavbarMenu, location);

  yield put(updatePhone({ phone, soft: true }));
  const l = location.toJS != null ? location.toJS() : location;
  layout = l.sku ? DEFAULT_LAYOUT : DEFAULT_HOME_PAGE_LAYOUT;

  if (l.sku && l.sku.includedInUpsellPlan) {
    yield call(loadUserProfileSaga);
    const subscription = yield select((state) => state.getIn(['user', 'account', 'subscription']));
    const cart = yield select((state) => state.getIn(['entities', 'cart']));
    let estimateResult = null;

    const { yearlyID } = getUpsellPlanIds({ isPlanPricingTest });
    let cartPlanId = null;
    let subscriptionPlanId = null;
    if (cart && cart.getIn(['plan', 'id'])) {
      cartPlanId = cart.getIn(['plan', 'id']);
    }
    if (subscription && subscription.get('id')) {
      subscriptionPlanId = subscription.get('planId');
    }
    estimateResult = yield call(routes.skus.estimate, {
      skuId: l.sku.id,
    },
     ( !cartPlanId && !subscriptionPlanId ? { enforce_plan_id:  yearlyID } : {} )
    );

    if (estimateResult && !estimateResult.err) {
      const { prices } = estimateResult.data;
      l.sku.subscriptionPrices = prices;
    }
  }

  yield put(layoutUpdate(layout));
  yield put(pageLoaded('location', { location, locationPath }));
}

function* selectLocationPageSaga({ locationPath, page }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.locations.find, { locationPath, page });

  if (response.err) {
    yield put(displayErrors(response));
    return;
  }
  const { location } = response.data;
  yield put(locationUpdated({ locationPath, location }));
  yield put(requestFinished());
}

function* pageFlow() {
  yield takeLatest((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
  yield takeLatest(SELECT_LOCATION_PAGE, selectLocationPageSaga);
}

export default [pageFlow];
