// Libraries
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
// Utils && Data
import { HIDE_PRODUCT_CONNECTED_HOME } from 'src/constants/app';
import HT_INFO from 'src/constants/hellotech';
import { faqPath, plansPath, productsIframeBasePath, homehubPath } from 'src/utils/paths';
// Components
import {
  ItemWithCaret,
  ICON_ORIENTATION as SERVICES_ICON_PLACEMENT,
} from 'src/components/HTKit/Elements/Menu';
// Styles
import styles from '../styles.scss';

const ITEMS_ROUTES = {
  services: [/\/tech-support/],
  products: [/\/products/],
  faq: [/\/faq/],
  membership: [/\/plans/],
  homehub: [/\/homehub/],
};

const CenterComponent = ({
  toggleTopnav,
  topNavIsOpen,
  showSiteLinks,
  hideMembership,
  themeStyles,
}) => {
  const location = useLocation();

  const activeItem = useMemo(() => {
    return Object.keys(ITEMS_ROUTES).find(
      (key) => ITEMS_ROUTES[key].some((regex) => regex.test(location.pathname)) && key,
    );
  }, [location.pathname]);

  const chevronStyles = cn(styles.chevron, themeStyles);
  const chevronUpStyles = cn(styles.chevronUp, themeStyles);

  const servicesTextStyles = cn('p1', styles.menuItem, styles.service, themeStyles, {
    [styles.active]: activeItem === 'services',
  });
  const membershipStyles = cn('p1', styles.menuItem, styles.memberships, themeStyles, {
    [styles.active]: activeItem === 'membership',
  });
  const homehubStyles = cn('p1', styles.menuItem, styles.memberships, themeStyles, {
    [styles.active]: activeItem === 'homehub',
  });
  const faqStyles = cn('p1', styles.menuItem, styles.faq, themeStyles, {
    [styles.active]: activeItem === 'faq',
  });
  const productsStyles = cn('p1', styles.menuItem, styles.faq, themeStyles, {
    [styles.active]: activeItem === 'products',
  });
  const partnershipStyles = cn('p1', styles.menuItem, styles.partnership, themeStyles);

  return (
    <div className={styles.centerContent}>
      {showSiteLinks && (
        <>
          <ItemWithCaret
            containerStyles={styles.servicesContainer}
            iconOrientation={SERVICES_ICON_PLACEMENT.right}
            iconName="chevron"
            iconStyles={chevronStyles}
            iconOpenStyles={chevronUpStyles}
            isOpen={topNavIsOpen}
            onClick={toggleTopnav}
            text="Services"
            textStyles={servicesTextStyles}
            textOpenStyles={styles.servicesActive}
            testId="nav-link-services"
          />
          {!HIDE_PRODUCT_CONNECTED_HOME && (
            <Link to={productsIframeBasePath()} className="-no-decoration">
              <p className={productsStyles}>Products</p>
            </Link>
          )}
          {!hideMembership && (
            <Link to={plansPath()} className="-no-decoration" data-testid="nav-link-memberships">
              <p className={membershipStyles}>Membership</p>
            </Link>
          )}
          {!HIDE_PRODUCT_CONNECTED_HOME && (
            <Link to={homehubPath()} className="-no-decoration">
              <p className={homehubStyles}>My Connected Home</p>
            </Link>
          )}
          <a data-testid="nav-link-partner-with-us" target="_blank" rel="noopener noreferrer" href={HT_INFO.siteLinks.partnerships.url}>
            <p className={partnershipStyles}>Partner with Us</p>
          </a>
          <Link to={faqPath()} className="-no-decoration" data-testid="nav-link-faq">
            <p className={faqStyles}>FAQ</p>
          </Link>
        </>
      )}
    </div>
  );
};

CenterComponent.propTypes = {
  clearAppNotices: PropTypes.func.isRequired,
  hideMembership: PropTypes.bool,
  showSiteLinks: PropTypes.bool.isRequired,
  toggleTopnav: PropTypes.func.isRequired,
  topNavIsOpen: PropTypes.bool.isRequired,
  themeStyles: PropTypes.string,
};

CenterComponent.defaultProps = {
  hideMembership: false,
  topNavIsOpen: false,
};

export default CenterComponent;
