import React from 'react';
import * as Yup from 'yup';
// Hooks
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  useAccountVerificationAndUpdate,
  useCreateAccount,
  usePasscodeModalCleanup,
} from './VerificationForm.hooks';
// Redux Actions and Selectors
import { pureUserSelector } from 'src/selectors/user';
import { orderIsFromApiSelector } from 'src/selectors/cart';
// Constants
import { INJECTED_USER_PHONE } from '../constants';
// Components
import Helmet from 'react-helmet';
import Button, { THEMES } from 'HTKit/Forms/Button';
import InputFieldV2 from 'HTKit/Forms/InputFieldV2';
import { Grid } from 'HTKit/Grid';
import Form from 'HTKit/Forms/Form';

const INVALID_EMAIL_ERROR = 'Invalid email address';

export const VerificationFormPasswordless = () => {
  /** The Password Auth flow skips the verification page, but for Passwordless we need to show it. */
  const isApiOrder = useSelector(orderIsFromApiSelector);

  const user = useSelector(pureUserSelector);
  const userPhone = user?.phone === INJECTED_USER_PHONE ? '' : user?.phone;

  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phone: userPhone || '',
  };

  const { debouncedCheckAccount, handleUpdateProfile } = useAccountVerificationAndUpdate();
  const { handleCreateAccount, emailSuggestion, emailValidationError } = useCreateAccount();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true, // Fill in fields after login
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string()
        .email(INVALID_EMAIL_ERROR)
        .required('Required'),
      phone: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      if (!user) {
        handleCreateAccount(values);
        return;
      }
      handleUpdateProfile(values);
    },
  });

  // Cleans up user info in Redux and email field in this form when passcode modal closes.
  usePasscodeModalCleanup({ formik });

  /** Handles email input changes, performs immediate validation, and checks for existing accounts */
  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    formik.setFieldValue('email', emailInput);

    // Skip validation if this is an API order because their account is already created
    if (isApiOrder) return;

    let errorMsg = '';
    try {
      // Synchronously validate the email input instead of waiting for formik.errors.email
      // to update. Waiting will cause the validation to be out of sync with the current input.
      Yup.string()
        .email(INVALID_EMAIL_ERROR)
        .required('Required')
        .validateSync(emailInput);
    } catch (validationError) {
      errorMsg = validationError.message;
    }

    if (!user && emailInput && emailInput.length > 2 && !errorMsg) {
      debouncedCheckAccount.cancel();
      debouncedCheckAccount(emailInput);
    }
  };

  const emailError = formik.errors.email || emailValidationError;

  return (
    <div className="paddingY-medium1">
      <Helmet title="Customer Verification" />
      <Grid.Fluid>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <h1 className="h3 paddingBottom-small1 text-align-center">Contact Information</h1>

              <Form.Row>
                <Form.Column>
                  <InputFieldV2
                    type="text"
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={handleEmailChange}
                    error={emailError}
                  />
                  {emailSuggestion && !emailError && (
                    <button
                      className="plainButton n700 caption"
                      onClick={() => formik.setFieldValue('email', emailSuggestion)}
                      type="button"
                    >
                      <>
                        Did you mean: <span className="underline">{emailSuggestion}</span>?
                      </>
                    </button>
                  )}
                </Form.Column>
              </Form.Row>

              <Form.Row>
                <Form.Column sm={2} md={4} lg={6}>
                  <InputFieldV2
                    type="text"
                    label="First Name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.errors.firstName}
                    data-hj-whitelist
                  />
                </Form.Column>
                <Form.Column sm={2} md={4} lg={6}>
                  <InputFieldV2
                    type="text"
                    label="Last Name"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.errors.lastName}
                    data-hj-whitelist
                  />
                </Form.Column>
              </Form.Row>

              <Form.Row>
                <Form.Column>
                  <InputFieldV2
                    type="tel"
                    label="Phone Number"
                    name="phone"
                    mask="phone"
                    placeholder="Please enter a valid number"
                    keepCharPositions
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.phone}
                  />
                </Form.Column>
              </Form.Row>

              <Form.Row>
                <Form.Column>
                  <Button mediumV2 theme={THEMES.V2PRIMARY} onClick={formik.handleSubmit}>
                    Continue
                  </Button>
                </Form.Column>
              </Form.Row>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </div>
  );
};
