import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import cn from 'classnames';
import debounce from 'lodash/debounce';
// Hooks
import { useFormik } from 'formik';
import useAPI from 'src/hooks/useAPI';
// Utils
import { logger } from 'src/utils/logger';
import { noop } from 'src/utils/event';
// Constants
import { AUTO_SUBMIT_DEBOUNCE_TIMEOUT_MS } from 'src/containers/LoginPage/Modal/constants';
// Components
import InputFieldV2 from 'src/components/HTKit/Forms/InputFieldV2';
import { CalloutBox, CALLOUTBOX_THEMES } from 'src/components/Elements/CalloutBox';
// Styles
import styles from './styles.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email address')
    .required('Email is required'),
});

/**
 * Handles email input and account checking for the initial step of passwordless authentication.
 */
export const LoginFormPasswordless = ({ onSuccess = noop, onError = noop, className }) => {
  const [showCallout, setShowCallout] = useState(false);

  const api = useAPI();

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        api.toggleLoader(true);

        const response = await api.users.checkAccount({ email: values.email });

        if (response.err) {
          const is404 = response.err.response.status === 404;
          const errorMessage = is404 ? response.data?.errors[0] : 'Something went wrong';
          throw new Error(errorMessage);
        }
        const { phone_last_four = '', user_initials = '' } = response.data;
        onSuccess({
          phoneLastFour: phone_last_four,
          userInitials: user_initials,
          email: values.email,
        });
      } catch (error) {
        setShowCallout(true);
        onError(error);
        logger('LoginFormPasswordless')(error);
      } finally {
        api.toggleLoader(false);
      }
    },
  });

  const debouncedSubmit = useCallback(
    debounce(() => {
      formik.submitForm();
    }, AUTO_SUBMIT_DEBOUNCE_TIMEOUT_MS),
    [formik.submitForm],
  );

  const handleEmailChange = (e) => {
    setShowCallout(false);
    formik.setErrors({});
    formik.setFieldValue('email', e.target.value);

    // Auto-submit if email is not empty and passes initial validation
    if (e.target.value && !formik.errors.email) {
      debouncedSubmit.cancel();
      debouncedSubmit();
    }
  };

  return (
    <div className={className}>
      <p className={cn('p1 n900', styles.subheader)}>
        Enter your email to access your HelloTech account
      </p>

      <InputFieldV2
        name="email"
        label="Email address"
        value={formik.values.email}
        onChange={handleEmailChange}
        onBlur={formik.handleBlur}
      />

      {/* Show this callout if the email doesn't match an existing account */}
      {showCallout && (
        <CalloutBox
          theme={CALLOUTBOX_THEMES.DEFAULT}
          header="We couldn't find an account matching that email address"
          text="Want to create an account? Simply book your first service!"
          className="marginTop-small2"
        />
      )}
    </div>
  );
};

LoginFormPasswordless.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  className: PropTypes.string,
};
