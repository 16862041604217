import { List as ImmutableList } from 'immutable';
import { createSelector } from 'reselect';
import { formatter as currencyFormatter } from 'src/components/MemberBenefits/helpers';
import { getDiscountsTotal } from 'src/containers/CartPage/Parts/ServicesBreakdown/utils';
import {
  COUPON_ADJUSTMENT_TYPES,
  ORDER_ADJUSTMENT_TYPES,
} from 'src/components/OrderSummary/Breakdown/constants';
import { PAYMENT_TYPES } from 'src/containers/BookingPage/PaymentPage/constants';
import { SERVICE_ADJUSTMENT_TYPES } from 'src/containers/Account/OrderPage/constants';
import { addExplanationToAdjustment } from 'src/utils/cart';
import { logger } from 'src/utils/logger';
import { BREAKDOWN_PRICE_TYPES } from 'src/containers/CartPage/constants';

const cartStateSelector = (state) => state.getIn(['entities', 'cart']);

export const cartSelector = createSelector(cartStateSelector, (cart) => cart);

export const pureCartSelector = createSelector(cartSelector, (cart) => cart && cart.toJS());

export const cartItemsJSSelector = createSelector(
  cartSelector,
  (cart) => cart && cart.get('items').toJS(),
);

export const cartBreakdownSelector = createSelector(
  cartSelector,
  (cart) => cart && cart.get('breakdown'),
);

/**
 * Service prices/discounts for an individual line item
 * @typedef {{amount: string, lineThroughText?: string, subTotal: {amount: string, lineThroughText?: string}}} Price - lineThroughText only appears for discounted member services
 *
 * @param {object} params
 * @param {boolean} [params.isItemPrepaid] - This is used for prepaid partner skus
 * @param {object} params.item
 * @param {array} params.item.adjustments - List of discounts
 * @param {number} params.item.skuAmount - Base price of sku in cents
 * @param {string} params.item.skuAmountFormatted
 * @param {number} params.item.savings - Membership discount
 * @param {number} params.item.amount - Total cost of sku item MINUS discounts
 * @returns {Price} {amount: string, lineThroughText?: string}
 */
export const getPricesAndDiscounts = ({ item, isItemPrepaid }) => {
  if (!item) return {};
  if (isItemPrepaid) return { amount: 'Prepaid' };
  const skuBasePrice = item.skuAmountFormatted;
  const discountsTotal = getDiscountsTotal(item);
  const subTotalObj = {
    amount: currencyFormatter(2).format(item.amount / 100),
    lineThroughText:
      discountsTotal > 0 ? currencyFormatter(2).format((item.amount + discountsTotal) / 100) : null,
  };
  return { amount: skuBasePrice, subTotal: subTotalObj };
};

/** Display item level adjustments, not including things such as discounts */
export const getFilteredServiceAdjustments = ({ adjustments, isItemPrepaid }) => {
  const removeSubscriptionDiscount = (adj) =>
    !adj.name
      .trim()
      .toLowerCase()
      .includes(SERVICE_ADJUSTMENT_TYPES.SHP_DISCOUNT);
  const removePrepaidDiscount = (adj) => !(adj.type === 'subsidy' && isItemPrepaid);
  const removeBundleDiscount = (adj) =>
    !(adj.name.trim().toLowerCase() === SERVICE_ADJUSTMENT_TYPES.AUTOMATIC_DISCOUNT);

  return adjustments
    .filter(removeSubscriptionDiscount)
    .filter(removePrepaidDiscount)
    .filter(removeBundleDiscount)
    .map((item) => {
      const { name, amountFormatted } = item;
      return { name, amount: amountFormatted };
    });
};

/**
 * For checkout summary and cart views. Use this to return a list of discounts applied to sku and the value of the discount
 * @typedef {{name: string, amountFormatted: string}} Adjustment
 * @param {Adjustment[]} adjustments
 * @param {[]} discounts SERVICE_ADJUSTMENT_TYPES.SHP_DISCOUNT | SERVICE_ADJUSTMENT_TYPES.AUTOMATIC_DISCOUNT
 * @returns string[]
 */

export const getFilteredServiceDiscounts = (
  adjustments = [],
  discounts = [SERVICE_ADJUSTMENT_TYPES.SHP_DISCOUNT, SERVICE_ADJUSTMENT_TYPES.AUTOMATIC_DISCOUNT],
) => {
  return adjustments.reduce((acc, elem) => {
    const isTargetedDiscount = discounts.includes(elem.name.trim().toLowerCase());
    const amount = elem.amountFormatted || elem.amount_formatted;
    if (isTargetedDiscount) acc.push({ name: elem.name, amount });
    return acc;
  }, []);
};

export const totalSavingsSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('totalSavings'),
);

export const totalSavingsFormattedSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('totalSavingsFormatted'),
);

export const badgeSavingsSelector = createSelector(cartBreakdownSelector, (breakdown) => {
  if (!breakdown) return null;
  // Total savings should now be directly derived from the cart breakdown
  const breakdownJS = breakdown.toJS();
  const { totalSavings = 0 } = breakdownJS;
  const amountFormatted = currencyFormatter(2).format(totalSavings / 100);
  return { amount: totalSavings, amountFormatted };
});

export const couponSelector = createSelector(cartSelector, (cart) => cart && cart.get('coupon'));

export const partnerIdSelector = createSelector(
  cartSelector,
  (cart) => cart && cart.getIn(['partner', 'id']),
);

export const partnerNameSelector = createSelector(
  cartSelector,
  (cart) => cart && cart.get('partnerName'),
);

/**
 * With the new redeemable membership flow (Innover), the partner object is nested
 * under the plan object. This selector will return the partner object from either the
 * normal partner object or under the plan object.
 */
export const partnerObjectAllAvenuesJSSelector = createSelector(cartSelector, (cart) => {
  if (cart) {
    const rootPartnerObject = cart.get('partner');
    const planPartnerObject = cart.getIn(['plan', 'partner']);
    const partnerObject = rootPartnerObject || planPartnerObject;
    return partnerObject && partnerObject.toJS();
  }
  return null;
});

export const planSelector = createSelector(cartSelector, (cart) => cart && cart.get('plan'));
export const purePlanSelector = createSelector(pureCartSelector, (cart) => cart && cart.plan);

export const planIdSelector = createSelector(
  cartSelector,
  (cart) => cart && cart.getIn(['plan', 'id']),
);

export const planNameSelector = createSelector(
  cartSelector,
  (cart) => cart && cart.getIn(['plan', 'name']),
);

export const planAmountSelector = createSelector(
  cartSelector,
  (cart) => cart && cart.getIn(['plan', 'amount']),
);

export const planIntervalSelector = createSelector(
  cartSelector,
  (cart) => cart && cart.getIn(['plan', 'planInterval']),
);

export const orderAdjustmentsSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('adjustments').toJS(),
);

// Filter out coupons because those are rendered on a separate line item on Booking Summary Page
export const orderAdjustmentsNoCouponSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => {
    if (!breakdown) return null;
    if (!breakdown.get('adjustments')) return null;
    return addExplanationToAdjustment(
      breakdown
        .toJS()
        .adjustments.filter((adj) => adj.type !== ORDER_ADJUSTMENT_TYPES.COUPON)
        .map(({ name, type, explanation, amountFormatted, originalAmountFormatted }) => {
          return {
            name,
            type,
            explanation,
            amount: amountFormatted,
            originalAmount: originalAmountFormatted,
          };
        }),
    );
  },
);

export const couponValueFormattedSelector = createSelector(
  cartBreakdownSelector,
  couponSelector,
  (breakdown, coupon) => {
    const breakdownJS = breakdown && breakdown.toJS();
    const breakdownConditions = breakdown && coupon && breakdownJS.adjustments.length;
    const amount =
      breakdownConditions &&
      breakdownJS.adjustments.find((adj) => adj.type === ORDER_ADJUSTMENT_TYPES.COUPON);
    return amount && amount.amountFormatted;
  },
);

export const couponValueSelector = createSelector(
  cartBreakdownSelector,
  couponSelector,
  (breakdown, coupon) => {
    if (!breakdown || !coupon) return null;

    const breakdownJS = breakdown.toJS();
    const breakdownHasItems = Boolean(breakdownJS.adjustments.length);
    const couponInfo =
      breakdownHasItems &&
      breakdownJS.adjustments.find((adj) => adj.type === ORDER_ADJUSTMENT_TYPES.COUPON);
    return couponInfo && couponInfo.amount;
  },
);

export const couponTextSelector = createSelector(
  couponSelector,
  (coupon) => coupon && coupon.get('text'),
);

export const subscriptionCouponSelector = createSelector(cartBreakdownSelector, (breakdown) => {
  if (!breakdown) return ImmutableList();
  return (
    breakdown
      .get('subscriptionAdjustments', ImmutableList())
      .filter((adj) => adj.get('type') === ORDER_ADJUSTMENT_TYPES.COUPON) || ImmutableList()
  );
});

export const subscriptionCouponAutoAppliedSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => {
    if (!breakdown) return null;

    const subAdjustments = breakdown.get('subscriptionAdjustments');
    const psuedoSubCoupon =
      subAdjustments &&
      subAdjustments
        .toJS()
        .find((adj) => adj.type === ORDER_ADJUSTMENT_TYPES.COUPON && adj.autoApplied);

    return psuedoSubCoupon;
  },
);

/* We only want to over-ride */
export const standardAndSubscriptionCouponSelector = createSelector(
  cartBreakdownSelector,
  couponSelector,
  (breakdown, coupon) => {
    if (!breakdown) return null;

    try {
      const standardAdjustments = breakdown.get('adjustments');
      /* Do we have coupons: which avenues */
      const adjustmentCoupon =
        standardAdjustments &&
        standardAdjustments.toJS().find((adj) => adj.type === ORDER_ADJUSTMENT_TYPES.COUPON);

      const subAdjustments = breakdown.get('subscriptionAdjustments');
      const psuedoSubCoupon =
        subAdjustments &&
        subAdjustments
          .toJS()
          .find((adj) => adj.type === ORDER_ADJUSTMENT_TYPES.COUPON && adj.autoApplied);
      const subCoupon =
        subAdjustments &&
        subAdjustments
          .toJS()
          .find((adj) => adj.type === ORDER_ADJUSTMENT_TYPES.COUPON && !adj.autoApplied);

      const couponName = coupon && coupon.get('text');

      /* ---- Determine Grouping ---- */
      const subCouponDefault = psuedoSubCoupon || subCoupon;
      const subscriptionCoupon = subCouponDefault && {
        ...subCouponDefault,
        name: psuedoSubCoupon ? subCouponDefault.name : couponName,
      };

      const couponGroup = {
        /* Legitimate coupon adjust can only exist if subscriptionAdjustment is an auto-applied. Otherwise its a dup object */
        [COUPON_ADJUSTMENT_TYPES.STANDARD]:
          adjustmentCoupon && (!subCoupon || (psuedoSubCoupon && subCoupon))
            ? {
                ...adjustmentCoupon,
                name: couponName,
              }
            : undefined,
        [COUPON_ADJUSTMENT_TYPES.SUBSCRIPTION]: subscriptionCoupon,
      };

      return couponGroup;
    } catch (err) {
      logger('standardAndSubscriptionCouponSelector: ', err);
      return {};
    }
  },
);

export const subscriptionCouponValueSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => {
    const breakdownJS = breakdown && breakdown.toJS();
    return (breakdownJS?.subscriptionAdjustments || []).find(
      (adj) => adj.type === ORDER_ADJUSTMENT_TYPES.COUPON,
    )?.amountFormatted;
  },
);

export const subscriptionCreditAdjustmentsSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => {
    if (!breakdown) return null;
    const breakdownJS = breakdown.toJS();
    return breakdownJS.subscriptionAdjustments.filter(
      (adj) => adj.type === ORDER_ADJUSTMENT_TYPES.CREDIT,
    );
  },
);

export const subscriptionTaxFormattedSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => {
    if (!breakdown || !breakdown.get('subscriptionTax')) return null;
    return breakdown.get('subscriptionTaxFormatted');
  },
);

export const subscriptionTotalFormattedSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('subscriptionTotalFormatted'),
);

export const serviceTotalFormattedSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('serviceTotalFormatted'),
);

export const itemsTotalFormattedSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('itemsTotalFormatted'),
);

export const totalFormattedSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('totalFormatted'),
);

export const totalServicesNonFormattedSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('total'),
);

export const orderSelector = createSelector(cartSelector, (cart) => cart.get('order'));

export const orderIsFromApiSelector = createSelector(cartSelector, (cart) =>
  cart.getIn(['order', 'fromApi']),
);

export const cardSelector = createSelector(cartSelector, (cart) => cart.get('card'));

export const pureItemQuestionsSelector = createSelector(pureCartSelector, (cart) => {
  if (!cart || !cart.items) return [];
  return cart.items.map((item) => item.questions);
});

export const cartHasPinsSelector = createSelector(pureCartSelector, (cart) => cart.hasPins);

export const affirmEligibleSelector = createSelector(pureCartSelector, (cart) =>
  Boolean(cart.affirmEligible),
);

export const affirmPaymentSelectedSelector = createSelector(
  pureCartSelector,
  (cart) => cart.paymentType === PAYMENT_TYPES.affirm,
);

export const paymentTypeSelector = createSelector(
  pureCartSelector,
  (cart) => cart.paymentType || PAYMENT_TYPES.card,
);

export const itemsQuantitySelector = createSelector(cartItemsJSSelector, (cartItems) =>
  cartItems.reduce((acc, item) => acc + item.quantity, 0),
);

/* helper to reduce boilerplate for hourly actions */
const breakdownJSHourlyAction = (action) => (breakdown) => {
  if (!breakdown) return false;
  const breakdownJS = breakdown.toJS();

  return breakdownJS.items?.[action]((item) => {
    return item.priceType === BREAKDOWN_PRICE_TYPES.hourly;
  });
};
/* We want to suppress upsell if all items are hourly */
export const allPriceTypeHourlySelector = createSelector(
  cartBreakdownSelector,
  breakdownJSHourlyAction('every'),
);

/* If we have any hour priceTypes, show disclaimer */
export const atLeastOnePriceTypeHourlySelector = createSelector(
  cartBreakdownSelector,
  breakdownJSHourlyAction('some'),
);
