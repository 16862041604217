// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import ContactIcon from 'src/components/Header/Parts/ContactIcon';
import CartPreviewIcon from 'src/components/Header/Parts/CartPreviewIcon';
import PhoneIcon from 'src/components/Header/Parts/PhoneIcon';
import SmallCallout from 'src/components/Header/Parts/SmallCallout';
import Icon from 'HTKit/Icon';
import PhoneDisplayLink, {
  ICON_ORIENTATION as PHONE_ICON_PLACEMENT,
} from 'src/components/PhoneDisplayLink';
import SearchBar from 'src/components/Header/TopNavHeader/Parts/SearchBar';
// Styles
import styles from '../styles.scss';

const AccountProfile = ({ onClick }) => (
  <Icon name="profile-outlined" className={styles.profileIcon} onClick={onClick} />
);

const RightComponent = ({
  cart,
  containerStyles,
  onEnter,
  onLeave,
  push,
  showSiteLinks,
  themeStyles,
  toggleAccountMenu,
  showSearchBar,
  showPhoneInfo,
  ...props
}) => {
  const phoneStyles = cn('p1', styles.clickToCall, themeStyles);
  const profileIconStyles = cn(styles.profileIcon, themeStyles);
  const wrapperStyles = cn({
    [styles.rightContent]: !showSearchBar,
    [styles.rightContentSearch]: showSearchBar,
  });
  const searchStyles = !showSearchBar ? styles.switchSearchIcon : styles.search;

  return (
    <div className={wrapperStyles}>
      {!showSearchBar && (
        <div onMouseEnter={onEnter} onMouseLeave={onLeave} className={styles.clickToCallWrapper}>
          <PhoneDisplayLink
            linkStyle={phoneStyles}
            displayNumber
            icon={<PhoneIcon />}
            iconOrientation={PHONE_ICON_PLACEMENT.left}
            splitCaller={true}
          />
          {showPhoneInfo && <SmallCallout text="24/7 Customer Support" />}
        </div>
      )}
      {!showSiteLinks && !showSearchBar && <ContactIcon className={styles.chatIcon} />}
      <div className={searchStyles}>
        {showSiteLinks && <SearchBar showSearchBar={showSearchBar} {...props} />}
        {!showSearchBar && (
          <CartPreviewIcon
            cart={cart}
            containerStyles={containerStyles}
            themeStyles={themeStyles}
          />
        )}
      </div>
      {showSiteLinks && !showSearchBar && (
        <Icon data-testid="nav-link-account" name="profile-outlined" className={profileIconStyles} onClick={toggleAccountMenu} />
      )}
    </div>
  );
};

AccountProfile.propTypes = { onClick: PropTypes.func };
AccountProfile.defaultProps = { onClick: () => {} };

RightComponent.propTypes = {
  cart: PropTypes.shape({}).isRequired,
  containerStyles: PropTypes.string,
  push: PropTypes.func.isRequired,
  showSiteLinks: PropTypes.bool.isRequired,
  showSearchBar: PropTypes.bool,
  showPhoneInfo: PropTypes.bool,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  toggleAccountMenu: PropTypes.func,
  themeStyles: PropTypes.string,
};

export default RightComponent;
