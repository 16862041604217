import React, { useState, useEffect, useRef, forwardRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Button, { THEMES } from 'HTKit/Forms/Button';
import useOutsideClick from 'src/hooks/useOutsideClick';
import InputFieldV2 from '../InputFieldV2';
import { replaceCountry } from './utils';
import { useAddressValidation, useGoogleMapsSDK } from './hooks';
import styles from './styles.scss';

const googleMapsOptions = {
  types: ['address'],
  componentRestrictions: { country: 'us' },
};

/**
 * The dropdown of suggested addresses from Google Maps or of a warning where
 * no addresses were found.
 */
const AddressList = ({
  suggestedAddresses,
  setActiveItem,
  setAddressFromActiveItem,
  addressListCanBeOpened,
  addressListUseAsEnteredState,
  setAsEntered,
  relativeMenu,
}) => {
  if (!Array.isArray(suggestedAddresses) || !addressListCanBeOpened) {
    return null;
  }

  const containerStyles = cn(styles.addressListContainer, {
    [styles.relative]: relativeMenu,
  });

  return (
    <div className={containerStyles}>
      {!addressListUseAsEnteredState ? (
        <div className="paddingY-tiny1" data-testid="addessAutocomplete-results">
          {suggestedAddresses.map((address) => {
            const { formattedSuggestion, index, active } = address;
            const { mainText, secondaryText } = formattedSuggestion;
            const itemStyles = cn(styles.addressItem, {
              [styles.active]: active,
            });

            return (
              <div
                key={index}
                className={itemStyles}
                onMouseOver={setActiveItem(index)}
                onMouseDown={setAddressFromActiveItem}
              >
                <p className={'p1'}>
                  <span className="text-weight-med">{mainText}</span> {secondaryText}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="paddingTop-tiny1 paddingX-small paddingBottom-small1">
          <p className={cn('p1', styles.noItems)}>Sorry, this address cannot be located.</p>
          <Button
            onClick={setAsEntered}
            inlineBlock
            smallV2
            theme={THEMES.V2SECONDARY}
            className="marginTop-tiny1"
          >
            Use address as entered
          </Button>
        </div>
      )}
    </div>
  );
};

AddressList.propTypes = {
  relativeMenu: PropTypes.bool,
  setAsEntered: PropTypes.func.isRequired,
  addressListCanBeOpened: PropTypes.bool,
  addressListUseAsEnteredState: PropTypes.bool,
  setAddressFromActiveItem: PropTypes.func.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  suggestedAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      index: PropTypes.number,
      placeId: PropTypes.string,
      formattedSuggestion: PropTypes.shape({
        mainText: PropTypes.string,
        secondaryText: PropTypes.string,
      }),
      suggestion: PropTypes.string,
    }),
  ),
};

/**
 * Notable Behaviors
 * - When the user clicks away from the input, the address value should revert to
 *   its previous state. For example, on an empty input, the user enters some text
 *   and clicks away, the input value should revert to an empty string.
 *    - Notice that the `onBlur` prop was not used for this behavior. This is because
 *      on the "No address found" state, the `onBlur` events fires before the `onClick`
 *      of the `Use adddress as entered` button, which is causing some UI issues. To
 *      solve this, we're using a custom hook, `useOutsideClick`, to revert the address
 *      state and to close the address list.
 * - This component relies on the Google Maps SDK to retrieve and validate addresses.
 *   However, there may be cases where the SDK does not loaded in time. In this case,
 *   the component will behave a normal input field. See `useGoogleMapsSDK` hook for
 *   more details.
 */
const AddressAutocomplete = forwardRef(({ value, onChange, relativeMenu, ...rest }, ref) => {
  // Used to help control UI behaviors
  const [inputIsDirty, setInputIsDirty] = useState(false);
  // UI state to prevent address list from prematurely opening when input changes for the first time
  const [addressInitialFetched, setAdressInitialFetched] = useState(false);
  // Keep track of the user's input before finalizing the address, aka calling onChange
  const [addressProxy, setAddressProxy] = useState('');
  // Suggested addresses from Google
  const [suggestedAddresses, setSuggestedAddresses] = useState([]);
  // Show input loader while address is being validated
  const [showInputLoader, setShowInputLoader] = useState(false);
  // internal ref of the input field
  const internalInputRef = useRef(null);
  // container ref
  const containerRef = useRef(null);

  const {
    googleMapsLoaded,
    bypassGoogleMaps,
    autocompleteService,
    autocompleteOK,
    placesService,
  } = useGoogleMapsSDK();

  const addressListCanBeOpened =
    googleMapsLoaded && inputIsDirty && !!addressProxy && addressInitialFetched;
  const addressListUseAsEnteredState = addressListCanBeOpened && !suggestedAddresses.length;

  // Prefill address state if `value` has a value...
  useEffect(() => {
    if (value !== addressProxy) {
      setAddressProxy(value);
    }
  }, [value]);

  /**
   * The component itself needs access to the input field's ref, so
   * we'll set set the external and internal refs here.
   */
  const setInputRefs = (el) => {
    if (ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current = el;
    }
    internalInputRef.current = el;
  };

  /**
   * As the user inputs their address, save the value to a temporary state
   * and look for suggested addresses from Google
   */
  const handleInputChange = (event) => {
    const { value: addressValue } = event.target;
    setAddressProxy(addressValue);
    setInputIsDirty(true);

    /**
     * If Google Maps SDK has not loaded, treat the component as a normal input
     */
    if (!googleMapsLoaded) {
      onChange(addressValue);
      return;
    }

    autocompleteService.current.getPlacePredictions(
      { ...googleMapsOptions, input: addressValue },
      (predictions, status) => {
        setAdressInitialFetched(true);
        if (status !== autocompleteOK.current) {
          setSuggestedAddresses([]);
          return;
        }

        const formattedSuggestion = (structured_formatting) => {
          return {
            mainText: structured_formatting.main_text,
            secondaryText: replaceCountry(structured_formatting.secondary_text),
          };
        };

        setSuggestedAddresses(
          predictions.map((p, idx) => ({
            suggestion: replaceCountry(p.description),
            active: false,
            index: idx,
            placeId: p.place_id,
            formattedSuggestion: formattedSuggestion(p.structured_formatting),
          })),
        );
      },
    );
  };

  const updateAddressState = (newAddress) => {
    onChange(newAddress);
    setAddressProxy(newAddress);
  };

  const { validateAddress } = useAddressValidation({
    placesService,
    updateAddressState,
    setShowInputLoader,
    setInputIsDirty,
    internalInputRef,
  });

  // Update address value and kick off validation
  const setAddressAndValidate = ({ newAddress, placeId }) => {
    onChange(newAddress);
    validateAddress({ placeId });
  };

  const resetStates = (_addressProxy) => {
    setInputIsDirty(false);
    setAddressProxy(_addressProxy);
    setSuggestedAddresses([]);
    setAdressInitialFetched(false);
  };

  // An active item is the item that is highlighted in the list via mouse hover or keyboard navigation.
  const setActiveItem = (index) => () => {
    setSuggestedAddresses(
      suggestedAddresses.map((suggestedAddress, idx) => {
        if (idx === index) {
          return { ...suggestedAddress, active: true };
        }
        return { ...suggestedAddress, active: false };
      }),
    );
  };

  const getActiveItem = () =>
    suggestedAddresses.find((suggestedAddress) => suggestedAddress.active);

  const getActiveItemIndex = () =>
    suggestedAddresses.findIndex((suggestedAddress) => suggestedAddress.active);

  const setAddressFromActiveItem = () => {
    const activeItem = getActiveItem();
    if (activeItem) {
      const { suggestion, placeId } = activeItem;
      setAddressAndValidate({ newAddress: suggestion, placeId });
      resetStates(suggestion);
    }
  };

  const setAsEntered = (e) => {
    e.stopPropagation(); // prevent useOutsideClick callback from firing
    setAddressAndValidate({ newAddress: addressProxy });
    resetStates(addressProxy);
  };

  const handler = () => {
    if (inputIsDirty) {
      resetStates(value);
    }
  };
  useOutsideClick(containerRef, handler, [value, inputIsDirty]);

  const onKeyDown = (e) => {
    if (!addressListCanBeOpened) {
      return;
    }

    const updateSuggestedAddress = (indexToUpdate) => {
      setSuggestedAddresses(
        suggestedAddresses.map((suggestedAddress, idx) => {
          if (idx === indexToUpdate) {
            return { ...suggestedAddress, active: true };
          }
          return { ...suggestedAddress, active: false };
        }),
      );
    };

    switch (e.key) {
      case 'ArrowUp': {
        // Prevent input cursor from going to the beginning when pressing up.
        e.preventDefault();
        const indexToUpdate =
          getActiveItemIndex() > 0 ? getActiveItemIndex() - 1 : suggestedAddresses.length - 1;
        updateSuggestedAddress(indexToUpdate);
        break;
      }
      case 'ArrowDown': {
        // Prevent input cursor from going to the end when pressing down.
        e.preventDefault();
        const indexToUpdate =
          getActiveItemIndex() >= 0 && getActiveItemIndex() < suggestedAddresses.length - 1
            ? getActiveItemIndex() + 1
            : 0;
        updateSuggestedAddress(indexToUpdate);
        break;
      }
      case 'Enter': {
        // Prevent form submission while menu is open.
        e.preventDefault();
        setAddressFromActiveItem();
        break;
      }
      case 'Escape':
      case 'Tab': {
        // ESC simply hides the menu. TAB will blur the input and move focus to
        // the next item; hide the menu so it doesn't gain focus.
        resetStates(value);
        break;
      }
      default:
        break;
    }
  };

  /**
   * The input should be usable if Google Maps SDK has loaded or if the check for the
   * SDK exceeded x seconds. We do not want to prevent the user entering input for
   * too long.
   */
  const disableInput = !bypassGoogleMaps;

  return (
    <div className={styles.container} ref={containerRef} data-testid="address-autocomplete">
      <InputFieldV2
        type="text"
        ref={setInputRefs}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
        disabled={disableInput}
        value={addressProxy}
        showLoader={showInputLoader}
        readOnly={showInputLoader}
        {...rest}
      />
      <AddressList
        suggestedAddresses={suggestedAddresses}
        setActiveItem={setActiveItem}
        setAddressFromActiveItem={setAddressFromActiveItem}
        addressListUseAsEnteredState={addressListUseAsEnteredState}
        addressListCanBeOpened={addressListCanBeOpened}
        setAsEntered={setAsEntered}
        relativeMenu={relativeMenu}
      />
    </div>
  );
});

AddressAutocomplete.propTypes = {
  /**
   * Callback function when the address changes
   */
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  /**
   * Changes menu position to `relative` from `absolute` so it will take up space in the DOM.
   * Generally used to overcome overflow issues in Modals.
   */
  relativeMenu: PropTypes.bool,
};

export default AddressAutocomplete;
