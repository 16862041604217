import React, { useState, useEffect } from 'react';
import { DYNAMIC_ONLINE_HEADER_LABELS } from 'src/containers/HomePage/constants';
import { useTransition, animated } from 'react-spring';
import styles from '../styles.scss';

const DynamicOnlineHeader = () => {
  const [index, setIndex] = useState(0);
  const transitions = useTransition(DYNAMIC_ONLINE_HEADER_LABELS[index], (item) => item.id, {
    from: { opacity: 0, transform: 'translate3d(10%, 0 ,0)', display: 'inline' },
    enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0%, 0, 0)' },
  });
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((state) => (state + 1) % DYNAMIC_ONLINE_HEADER_LABELS.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <h1 className={styles.staticText}>In-Home & Online Support for your</h1>&nbsp;
      <span className={styles.dynamicText}>
        {transitions.map(({ item, props, key }) => (
          <animated.div key={key} style={{ ...props, position: 'absolute' }}>
            {item.title}
          </animated.div>
        ))}
      </span>
    </div>
  );
};

export default DynamicOnlineHeader;
